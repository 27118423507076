export const countryStateMap = {
  'AU-NSW': {
    name: 'Australia - New South Wales',
    capital: 'Sydney',
    coordinates: { lat: -33.83, lng: 151 },
    contactInfo: {
      email: 'customerservice@tcnsw.com.au',
      phone: '1800 290 691',
      facebookUrl: 'https://www.facebook.com/ReturnandEarn'
    },
    features: { HAS_BAGDROP: false, HAS_QR_CODE: false, HAS_BARCODE: true, HAS_CHARITY: true },
    env: 'au',
    prodEnabled: true
  },
  'AU-QLD': {
    name: 'Australia - Queensland',
    capital: 'Brisbane',
    coordinates: { lat: -27.47, lng: 153.02 },
    contactInfo: { email: 'help.tca@tomra.com', phone: '', facebookUrl: 'https://www.facebook.com/myTOMRAAustralia' },
    features: { HAS_BAGDROP: false, HAS_QR_CODE: false, HAS_BARCODE: true, HAS_CHARITY: false },
    env: 'au',
    prodEnabled: true
  },
  'AU-NT': {
    name: 'Australia - Northern Territory',
    capital: 'Darwin',
    coordinates: { lat: -12.46, lng: 130.84 },
    contactInfo: { email: 'help.tca@tomra.com', phone: '', facebookUrl: 'https://www.facebook.com/myTOMRAAustralia' },
    features: { HAS_BAGDROP: true, HAS_QR_CODE: false, HAS_BARCODE: true, HAS_CHARITY: false },
    env: 'au',
    prodEnabled: true
  },
  'CA-QC': {
    name: 'Canada - Quebec',
    capital: 'Ottawa',
    coordinates: { lat: 45.4215296, lng: -75.69719309999999 },
    contactInfo: { email: '', phone: '', facebookUrl: '' },
    features: { HAS_BAGDROP: false, HAS_QR_CODE: false, HAS_BARCODE: false, HAS_CHARITY: false },
    env: 'us',
    prodEnabled: true
  },
  'GB-ENG': {
    name: 'England',
    capital: 'London',
    coordinates: { lat: 51.5073509, lng: -0.1277583 },
    contactInfo: { email: 'support.tomraproducts@tomra.com', phone: '', facebookUrl: '' },
    features: { HAS_BAGDROP: false, HAS_QR_CODE: false, HAS_BARCODE: false, HAS_CHARITY: false },
    env: 'eu',
    prodEnabled: true
  },
  'GB-SCT': {
    name: 'Scotland',
    capital: 'Edinburgh',
    coordinates: { lat: 55.95, lng: -3.19 },
    contactInfo: { email: 'support.tomraproducts@tomra.com', phone: '', facebookUrl: '' },
    features: { HAS_BAGDROP: false, HAS_QR_CODE: true, HAS_BARCODE: true, HAS_CHARITY: false },
    env: 'eu',
    prodEnabled: true
  },
  PT: {
    name: 'Portugal',
    capital: 'Lisbon',
    coordinates: { lat: 38.7222524, lng: -9.1393366 },
    contactInfo: { email: 'support.tomraproducts@tomra.com', phone: '', facebookUrl: '' },
    features: { HAS_BAGDROP: true, HAS_QR_CODE: false, HAS_BARCODE: true, HAS_CHARITY: false },
    env: 'eu',
    prodEnabled: true
  },
  RO: {
    name: 'Romania',
    capital: 'Bucharest',
    coordinates: { lat: 44.4267674, lng: 26.1025384 },
    contactInfo: { email: 'support.tomraproducts@tomra.com', phone: '', facebookUrl: '' },
    features: { HAS_BAGDROP: false, HAS_QR_CODE: false, HAS_BARCODE: false, HAS_CHARITY: false },
    env: 'eu',
    prodEnabled: true
  },
  NO: {
    name: 'Norway',
    capital: 'Oslo',
    coordinates: { lat: 59.9138688, lng: 10.7522454 },
    contactInfo: { email: 'support.tomraproducts@tomra.com', phone: '', facebookUrl: '' },
    features: { HAS_BAGDROP: false, HAS_QR_CODE: false, HAS_BARCODE: false, HAS_CHARITY: false },
    env: 'eu',
    prodEnabled: false
  },
  'US-NY': {
    name: 'United States - New York',
    capital: 'New York',
    coordinates: { lat: 40.713, lng: -74.006 },
    contactInfo: {
      email: 'mytomrasupport.am@tomra.com',
      phone: '',
      facebookUrl: 'https://www.facebook.com/RetrievalCenters'
    },
    features: { HAS_BAGDROP: true, HAS_QR_CODE: true, HAS_BARCODE: false, HAS_CHARITY: false },
    env: 'us',
    prodEnabled: true
  },
  'US-CT': {
    name: 'United States - Connecticut',
    capital: 'Hartford',
    coordinates: { lat: 41.76, lng: -72.68 },
    contactInfo: {
      email: 'mytomrasupport.am@tomra.com',
      phone: '',
      facebookUrl: 'https://www.facebook.com/RetrievalCenters'
    },
    features: { HAS_BAGDROP: true, HAS_QR_CODE: false, HAS_BARCODE: true, HAS_CHARITY: false },
    env: 'us',
    prodEnabled: true
  }
}
